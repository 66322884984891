<template>
  <div>
    <!-- no longer using the import version to reduce bundle size -->
    <link rel="stylesheet" href="https://uicdn.toast.com/editor/latest/toastui-editor.min.css" />

    <v-input :disabled="disabled" :prepend-icon="prependIcon" class="markdown-editor" :hide-details="hideDetails">
        <p class="text-muted mb-0">{{label}}</p>
        <div :disabled="disabled" class="markdown-editor--preview" @click="openEditor">
            <vue-markdown v-if="value" class="markdown " :html="false" :source="value" />
            <div v-else><i class="text-muted">- Empty - </i></div>
            <v-btn :disabled="disabled" rounded small color="white" @click="openEditor"><v-icon small class="mr-2">fa-pencil</v-icon> Edit</v-btn>
        </div>
    </v-input>

    <v-dialog v-model="editorDialogVisible" fullscreen>
      <v-card color="white" class="">
        <v-toolbar dark color="grey darken-4">
          <v-btn icon dark class="ml-2" @click="editorDialogVisible = false">
            <v-icon>fa-times-circle</v-icon>
          </v-btn>
          <v-toolbar-title>Edit {{label}}</v-toolbar-title>
          <v-spacer/>
          <v-btn @click="submit"><v-icon class="mr-2">fa fa-check</v-icon> Ok</v-btn>
        </v-toolbar>
        <div class="full-dialog">
          <div v-if="description" id="editorDescription" class="pa-4 grey lighten-4 text-muted">{{description}}</div>
          <div ref="editor" class="markdown" ></div>
          <div v-if="placeholders" id="editorPlaceholders" class="pa-4 grey lighten-4 text-muted">
            You may include the following placeholders (including curly brackets) to include dynamic data:
            <ul>
              <li v-for="(key, idx) in Object.keys(placeholders)" :key="idx"><strong>{{ '{' + key+ '}'}}</strong> = {{placeholders[key]}}</li>
            </ul>
          </div>
        </div>
      </v-card>

    </v-dialog>
  </div>
</template>

<script>

import { EventBus } from '@/plugins/eventbus.js';
import { mapGetters } from "vuex";

import eventManagerService from "@/services/eventManagerService";
import VueMarkdown from '@/components/VueMarkdown.vue'

// note: no longer using the import version to reduce bundle size
//import Editor from '@toast-ui/editor';
//import '@toast-ui/editor/dist/toastui-editor.css'; 


export default {
  name: "MarkdownEditor",
  components: {
      VueMarkdown,
  },
  props: {
    eventId: String,
    value: String,
    label: String,
    description: String,
    placeholders: Object,
    prependIcon: String,
    hideDetails: Boolean,
    disabled: { type: Boolean, default: false },
  },
  emits: [
    'input'
  ],
  data: function() {
    return {
        editorDialogVisible: false,
        editor: null,
    };
  },
  created() {
  },
  mounted() {
    
  },
  updated() {
  },
  methods: {
    loadScript (scriptUrl) {
      const script = document.createElement('script');
      script.src = scriptUrl;
      document.body.appendChild(script);
      
      return new Promise((res, rej) => {
        script.onload = function() {
          res();
        }
        script.onerror = function () {
          rej();
        }
      });
    },
    async openEditor() {
        if (this.disabled) {
          return;
        }
        this.editorDialogVisible = true;
        await this.$nextTick();
        if (!this.editor) {
          //await this.loadScript('/js/uicdn.toast.com_editor_latest_toastui-editor-all.min.js');
          //    <!-- no longer using the import version to reduce bundle size -->
          await this.loadScript('https://uicdn.toast.com/editor/latest/toastui-editor-all.min.js');
          //console.log('///', window.toastui, window.Editor);
          var descriptionHeight = this.description ? window.document.getElementById('editorDescription').offsetHeight : 0;
          var placeholdersHeight = this.placeholders ? window.document.getElementById('editorPlaceholders').offsetHeight : 0;
          this.editor = new window.toastui.Editor({
              el: this.$refs.editor,
              height: `calc(100vh - 64px - ${descriptionHeight}px - ${placeholdersHeight}px)`,
              //height: 'auto',
              //minHeight: '100%',
              //height: '100%',
              usageStatistics: false,
              initialEditType: 'wysiwyg', //'markdown',
              initialValue: this.value || '',
              previewStyle: 'vertical',
              hooks: {
                  addImageBlobHook: this.uploadImage,
              }
          });
        }
        else {
          this.editor.focus();
          this.editor.setMarkdown(this.value);
        }
    },
    async uploadImage(blob, callback) {
      let formData = new FormData()
      formData.append("file", blob, blob.name);
      const response = await eventManagerService.upload(this.eventId, /*type:*/ 'ugc', formData);
      if (response.data.status == 'OK') {
          callback(response.data.id, "");
      }
      return false;
    },
    submit() {
        if (this.editor) {
          // Note: we need to convert back escaped underscores because some placeholders will contain these and they will not work when escaped!
            const markdown = this.editor.getMarkdown().replace(/\\[_]/g, `_`);
            console.log('Submitting', markdown);
            this.$emit('input', markdown);
            this.editorDialogVisible = false;
        }
    }
  },
  watch: {
  },
  computed: {
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed';
    },
  },
};
</script>

<style lang="scss">
    .markdown-editor {
        .v-input__slot { align-items: flex-start; flex-direction: column; }
        .markdown-editor--preview { 
            border: solid 1px rgba(0, 0, 0, 0.12); 
            background-color: #f5f5f5; 
            padding: 8px 5px;  
            width: 100%; 
            max-height: 100px;
            font-size: 90%;
            text-overflow: clip;
            overflow-y: scroll;
            cursor: pointer;
            position: relative;
            min-height: 40px;

            .v-btn {
                position: absolute;
                right: 5px;
                top: 5px;
            }
        }
    }
  .full-dialog { 
      width:100vw; 
      height: calc(100vh-64px);
      /*.toastui-editor-defaultUI { position: absolute; top:0; bottom: 0; right: 0; left: 0;}*/
    }

</style>
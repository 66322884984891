<template>
  <div v-if="race && race.route">
    <v-dialog v-model="visible" max-width="90%">
      <v-card>
        <v-toolbar dark color="grey darken-4">
          <v-btn icon dark class="ml-2" @click="visible = false">
            <v-icon>fa-times-circle</v-icon>
          </v-btn>
          <v-toolbar-title>Add a badge along the (virtual) course</v-toolbar-title>
          <v-spacer/>
          <v-btn v-if="race.dist || race.collective_goal" dark class="ml-2" @click="saveMarker">
            <v-icon class="mr-2">fa-check</v-icon>
            {{ this.existingBadge ? 'Save' : 'Add Badge' }}
          </v-btn>
        </v-toolbar>
        <v-alert v-if="!race.dist && !race.collective_goal" type="error" tile>This course doesn't yet have a goal distance assigned. Edit the leaderboard and assign a goal before proceeding.</v-alert>
        <v-card-text class="mt-4"><strong>Drag the marker along the course</strong> until it's at the right location and click 'Add Badge' to finalize the badge configuration.</v-card-text>
        <v-card-text>Note: uploading a new course map after adding badges may mis-align the badges so please make sure the course is correct before adding badges.</v-card-text>
        <LeafletMap
          ref="leaflet"
          :auto-load="false"
          style="height: 400px; width: 100%; z-index: 2;"
          :loader="loadMap"
          />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PolylineUtil from "@/plugins/Polyline.encoded.js";
import tenants from '@/data/tenants.config'
import LeafletMap from './LeafletMap.vue';
const tenant = tenants.current();

export default {
  name: "RaceGeoFenceDialog",
  components: {
    LeafletMap
  },
  props: {
    event: Object,
    race: Object,
    color: String,
  },
  data() {
    return {
      visible: false,
      zoom: 11,
      value: 0,
      //map: null,
      existingBadge: null,
      widthHeightRatio: 1,
      marker: null,
      badgeOptions: null,
      polyline: null,
      routePolyDecoded: null,
      courseLatLngs: null,
      markerIcon: window.L.icon({
        iconSize: [25, 41],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40],
        // specify the path here
        iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
        shadowUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png"
      }),
    }
  },
  async mounted() {

  },
  methods: {
    async open(badge) {
      this.visible = true;
      this.existingBadge = badge;
      if (badge && this.marker) {
        this.marker.setLatLng(this.getCoordinateAtMarkerValue());
      }
    },

    async saveMarker() {
      this.visible = false;
      if (this.existingBadge) {
        console.log('Saving map now.', this.existingBadge);
        this.existingBadge.value = this.value;
        this.$emit('save', this.existingBadge);
      }
      else if (this.race.scoring === 'TRACK') {
        var latLng = this.marker.getLatLng();
        const badge = {
          metric: 'COORDINATE',
          color: tenant.theme.primary,
          show_map: false,
          active: true,
          value: 50, // meters?
          coord_lat: latLng.lat,
          coord_lng: latLng.lng,
          ...this.badgeOptions,
        };
        console.log('Saving coordinate badge now.', badge);
        this.$emit('save', badge);
      }
      else {
        const badge = {
          metric: 'SCORE',
          unit: 'FIXED',
          color: tenant.theme.primary,
          shape: 'rectangular',
          show_map: true,
          active: true,
          value: this.value,
          ...this.badgeOptions,
        };
        console.log('Saving map now.', badge);
        this.$emit('save', badge);
      }
    },

    async ensureMapLoaded() {
      await this.initMap();
      //this.loadMap();
    },

    async initMap() {
      if (this.race == null || this.event == null || this.map != null) {
        console.log('No data (yet) to load.', this.race, this.event, this.map);
        return;
      }
      await this.$nextTick();
      console.log('Preparing course map.');
      if (!this.$refs.leaflet) {
        return;
      }
      if (this.race.custom_map && this.race.custom_map.img) {
        await this.$refs.leaflet.initCustomMap(this.race.custom_map);
      }
      else {
        await this.$refs.leaflet.initMap();
      }
    },

    getCoordinateAtMarkerValue() {
      var totalCourse = this.getDistanceTillCoord(this.courseLatLngs, {});
      var scaledValue = this.existingBadge.value * ((totalCourse) / (this.race.dist || this.race.collective_goal));
      var meters;
      if (this.race.scoring === 'STEPS') {
        meters = scaledValue; // actually: steps
      }
      else if (this.race.scoring === 'TIME') {
        meters = scaledValue; // actually: seconds
      }
      else {
        meters = Math.round(scaledValue * (this.event.unit == 'METRIC' ? 1000 : 1609.344));
      }
      console.log('[add along course] loading initial marker location at ', this.existingBadge.value, 'in m:', meters, 'of', totalCourse, (this.race.dist || this.race.collective_goal));
      return this.getLatLngAtDistance(this.courseLatLngs, meters);
    },

    loadMap() {
      this.routePolyDecoded = this.race.route == null ? null : PolylineUtil.decode(this.race.route);
      //this.courseLatLngs = this.race.route == null ? null : this.routePolyDecoded.map(x => window.L.latLng(x));
      //console.log('Showing course map now:', this.routePolyDecoded, this.$refs.leaflet);
      const map = this.$refs.leaflet.map;
      this.$refs.leaflet.addPolyline(this.routePolyDecoded, { color: '#ffffff', weight: 8, stroke: true, fill: false });
      this.polyline = this.$refs.leaflet.addPolyline(this.routePolyDecoded, { color: '#008AFF', weight: 5, stroke: true, fill: false });
      this.courseLatLngs = this.polyline.getLatLngs();
      console.log('[add along course] this.polyline', this.polyline, this.existingBadge);
      if (!this.isCustomMap) this.$refs.leaflet.map.setMaxBounds(null); // reset to prevent clipping when course is changed
      if (!this.isCustomMap) this.$refs.leaflet.map.fitBounds(this.polyline.getBounds().pad(.1 /* 10% */));
      if (!this.isCustomMap) this.$refs.leaflet.map.setMaxBounds(this.polyline.getBounds().pad(.5 /* 50% */));

      var markerCoord = this.startCoord;
      if (this.existingBadge) {
        markerCoord = this.getCoordinateAtMarkerValue();
      }
      //this.marker = this.$refs.leaflet.addMarker(this.startCoord);
      this.marker = new L.marker(markerCoord, {draggable:'true', icon: this.markerIcon, title: 'Drag badge location'}).addTo(map);
      const self = this;
      this.marker.on('dragend', function(event){
        var marker = event.target;
        var position = self.getNearestPointToPolyline(marker.getLatLng(), self.courseLatLngs);
        var distOverCourse = self.getDistanceTillCoord(self.courseLatLngs, position);
        var totalCourse = self.getDistanceTillCoord(self.courseLatLngs, {});
        var meters = Math.round((distOverCourse/totalCourse) * (self.race.dist || self.race.collective_goal));
        if (self.race.scoring === 'STEPS') {
          self.value = meters; // actually: steps
        }
        else if (self.race.scoring === 'TIME') {
          self.value = meters; // actually: seconds
          /*self.badgeOptions = {
            metric: 'DURATION',
          };*/
        }
        else {
          self.value = Math.round(meters / (self.event.unit == 'METRIC' ? 1000 : 1609.344));
        }
        console.log('distance over course', meters, 'm, score value:', self.value);
        marker.setLatLng(new L.LatLng(position.lat, position.lng));
        map.panTo(new L.LatLng(position.lat, position.lng));
      });

    },

    getNearestPointToPolyline(markerLatLng, polylineLatLngs) {
      var nearestIndex = 0,
          nearestDistance;
          //markerLatLng = latLng,//marker.getLatLng(),
          //polylineLatLngs = polyline.getLatLngs();
      for (var i = 0; i < polylineLatLngs.length; i++) {
        var distance = markerLatLng.distanceTo(polylineLatLngs[i]) //distance en mètres
        if (!nearestDistance || nearestDistance > distance) {
          nearestIndex = i
          nearestDistance = distance
        }
      }
      return polylineLatLngs[nearestIndex];
    },

    getDistanceTillCoord(latLngs, marker) {
      var distOverTrack = 0;
      var prev = latLngs[0];
      for (const latLng of latLngs) {
        const dist = prev.distanceTo(latLng);
        distOverTrack += dist;
        if (latLng.lat == marker.lat && latLng.lng == marker.lng) {
          return distOverTrack;
        }
        prev = latLng;
      }
      return distOverTrack;
    },
    getLatLngAtDistance(latLngs, distance) {
      var distOverTrack = 0;
      //var ratio = this.race.route_ratio || 1.0;
      //distance /= (this.race.route_ratio/1000);
      var prev = latLngs[0];
      for (const latLng of latLngs) {
        if (prev == latLng) {
          // skip
        }
        else {
          const dist = prev.distanceTo(latLng);
          distOverTrack += dist;
          if (distOverTrack >= distance) {
            const overshoot = distOverTrack - distance;
            const overshootRatio = dist == 0 ? 0 : 1-(overshoot / dist);
            return this.midpoint(prev, latLng, overshootRatio);
          }
        }
        prev = latLng;
      }
      return prev; // overshoot, return last valid point
    },
    midpoint(coord1, coord2, ratio) {
        return window.L.latLng(coord1.lat + (coord2.lat - coord1.lat) * ratio, coord1.lng + (coord2.lng - coord1.lng) * ratio);
    },
  },
  computed: {
    isCustomMap() {
      return this.race && this.race.custom_map && this.race.custom_map.img;
    },
    startCoord() {
      return this.polyline == null ? null : this.courseLatLngs[0];
    },
    stopCoord() {
      return this.polyline == null ? null : this.courseLatLngs[this.courseLatLngs.length - 1];
    },
  },
  watch: {
    async visible(value) {
      if (value) {
        await this.$nextTick();
        await this.ensureMapLoaded();
      } 
    },
    async race() {
      this.polygon = null;
      if (this.map) {
      }
    }
  },
};
</script>

<style lang="scss">
  .full-dialog { width:100vw;height:100vh; }
</style>